<script setup lang="ts">
definePageMeta({
  navbar_text: "white",
  navbar_color: "primary",
  // footer_class: '-mt-nav',
});

// const localeRoute = useLocaleRoute();

const { t, locale } = useI18n();

const { data: reviews } = await useAsyncData<any>(locale.value + "_page_faq_categories", () =>
  useNuxtApp().$siteFetch("/v1/content/reviews", {
    params: {
      "page[size]": 10,
    },
  })
);

useSeoMeta({
  title: t("pages.index.title"),
  description: t("pages.index.description"),
});
</script>

<template>
  <div class="">
    <div class="bg-primary text-white">
      <div class="container py-4 lg:py-16">
        <Search />
      </div>
    </div>

    <div class="bg-primary">
      <div class="bg-white rounded-t-3xl">
        <div class="container">
          <Places class="pt-16">
            <template #title> {{ $t('pages.index.header_title') }} </template>
            <template #subtitle> {{ $t('pages.index.header_subtitle') }} </template>
          </Places>

          <section class="my-8">
            <div class="bg-primary text-white rounded-3xl p-8 md:p-16">
              <div class="">
                <h2 class="text-3xl font-extrabold leading-tight tracking-tight md:text-4xl">
                  {{ $t("pages.index.about_title") }}
                </h2>
                <p class="mt-4 opacity-90 md:text-xl">
                  {{ $t("pages.index.about_subtitle") }}
                </p>
              </div>
            </div>
          </section>

          <section v-if="reviews.data.length > 0">
            <div class="py-16 mx-auto ">
              <div class="max-w-screen-lg">
                <h3 class="mb-4 text-4xl tracking-tight font-extrabold">
                  {{ $t("pages.others-whywe.reviews_title") }}
                </h3>
                <p class="mb-8 font-light text-gray lg:mb-16 sm:text-xl">
                  {{ $t("pages.others-whywe.reviews_subtitle") }}
                </p>
              </div>
              <div class="columns-1 md:columns-3 gap-6 space-y-6">
                <div v-if="reviews" class="break-inside-avoid-column h-min w-full" v-for="(review, index) in reviews.data" :key="index">
                  <v-card class="border-gray-tonal hover:shadow-sm">
                    <v-card-text class="text-gray">
                      <div class="flex">
                        <div class="flex-auto">
                          <p class="text-lg font-semibold text-black">{{ review.parking ?? "Parking" }}</p>
                          <p class="text- font-medium text-gray">{{ review.client }} - {{ review.period }}</p>
                        </div>
                        <div>
                          <span class="bg-orange px-2 py-1 rounded-xl text-white font-bold">
                            <VIcon :icon="$vuetify?.icons?.aliases?.ratingFull" class="text-sm" />
                            {{ review.rate.toFixed(2) }}
                          </span>
                        </div>
                      </div>
                      <div class="mt-4 text-lg text-black">
                        {{ review.content }}
                      </div>
                    </v-card-text>
                  </v-card>
                </div>
              </div>
            </div>
          </section>

          <Blogs />

          <section class="mb-16 mt-8">
            <div class="bg-black text-white rounded-3xl p-8 md:p-16">
              <div class="">
                <h2 class="text-3xl font-extrabold leading-tight tracking-tight md:text-4xl">
                  {{ $t("pages.index.benefit_title") }}
                </h2>
                <p class="mt-4 opacity-60 md:text-xl">
                  {{ $t("pages.index.benefit_subtitle") }}
                </p>
              </div>

              <div class="mt-8 md:mt-16">
                <v-row>
                  <v-col cols="12" md="4" v-for="i in 6" class="md:p-6">
                    <div class="flex flex-col items-start gap-4 md:gap-5 md:flex-row">
                      <div class="bg-primary rounded-full w-8 h-8 lg:w-12 lg:h-12 flex items-center justify-center shrink-0 sm:hidden">
                        <svg class="w-5 lg:w-6 h-5 lg:h-6 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.837 17.837" xml:space="preserve">
                          <path style="fill: currentColor" d="M16.145 2.571a.7.7 0 0 0-.99 0L6.92 10.804l-4.241-4.27a.698.698 0 0 0-.989 0L.204 8.019a.703.703 0 0 0 0 .99l6.217 6.258a.704.704 0 0 0 .99 0L17.63 5.047a.7.7 0 0 0 0-.994l-1.485-1.482z" />
                        </svg>
                      </div>
                      <div>
                        <h3 class="text-xl font-bold md:text-2xl">
                          {{ $t("pages.index.benefit_title_" + i) }}
                        </h3>
                        <p class="mt-2 opacity-60 md:text-lg">
                          {{ $t("pages.index.benefit_subtitle_" + i) }}
                        </p>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>
